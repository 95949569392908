.changemapFileAbsorbingColumn{
    width: 20%;
}

.changemapFile{
    width: 30%;
}

.changemapFileSize{
    width: 15%;
}

.changemapLastUpdated{
    width: 20%;
}

.changemapError{
    width: 15%;
}

.changeMapInfoModal {
    width: 1000px;    /* Occupy the 90% of the screen width */
    max-width: 1250px;
}

.form-inline {
    margin: 0 auto;
}

.changeMsg {
    color: #000000;
}
