.Process {
  text-align: center;
}

.process-title {
    color: #282c34;
    width: 55%;
    text-align: center;
    margin-right: 75px;
}

.Process-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Process-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Process-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Process-link {
  color: #61dafb;
}

.card-title {
    color: #282c34;
    width: 55%;
    text-align: right;
    margin-right: 75px;
}

.btnAddProcess {
  margin-left: -2%;
}

.btnAddProcess:hover {
    background-color: #f92043;
    border-color: #f92043;
}

.btnAddProcess:active {
    background-color: #f92043 !important;
    border-color: #f92043 !important;
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0px #f92043 !important;
}

.btnAddProcess:focus {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
    box-shadow: 0 0 3px 3px #f92043;
}
