.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pageTitle {
    color: #282c34;
    padding-top: 0px;
}

.loginContainer {
    padding-top: 100px;
    max-width: 400px;
}

.mainBody {
    height: calc(100vh - 88px);
    background-color: #FFFFFF;
}

.mainBodyMin {
    height: calc(100vh - 133px);
    background-color: #FFFFFF;
}

.mainBottomButton {
    height: 38px;
    background-color: #FFFFFF;
}

.mainBottomButtonExpanded {
    height: 45px;
    background-color: #FFFFFF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumboHeader {
    padding: 2rem 2rem 0rem 0rem;
}

.prgUpload {
    height: 40px;
}

.progress-bar {
    background-color: #d1001f;
}
