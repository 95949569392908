.CategoryList {
  text-align: center;
}

.CategoryMarker {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 40px;
}

.CategoryItem {
    color: #282c34;
    text-align: left;
    margin-right: 15px;
    min-width: 350px;
}

.CategoryBody {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    margin: auto;
}
