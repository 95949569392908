.Profile {
  text-align: center;
}

.Profile-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Profile-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.title {
    padding-left: 60px;
    padding-right: 20px;
}

.Profile-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Profile-link {
  color: #61dafb;
}

.btn {
  color: #fff;
  background-color: #d90023;
  border-color: #d90023;
}

.btnProfile {
  margin-left: 20px !important;
}

.btnProfile:hover {
    background-color: #f92043;
    border-color: #f92043;
}

.btnProfile:active {
    background-color: #f92043 !important;
    border-color: #f92043 !important;
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0px #f92043 !important;
}

.btnProfile:focus {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
    box-shadow: 0 0 3px 3px #f92043;
}

.userProfile:hover {
    text-decoration: underline;
}

@keyframes Profile-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
