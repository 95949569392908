.EndpointLabel {
    color: #282c34;
}

.EndpointSubmitLabel {
    color: #282c34;
}

.EndpointForm {
    width: 50%;
    margin: auto;
}

.EndpointControl {
    border: 1px solid #282c34;
    margin-top: 6px;
}

.EndpointSubmit {
    margin-top:25px;
    text-align: left;
}

.chkEndpoint {
    margin-top: 30px;
    color: #282c34;
    transform: scale(2);
    margin-right: 75px;
}
