.Login {
  text-align: center;
}

.loginLabel {
    float: left;
    padding-top: 10px;
}

.divSubmit {
    padding-top: 20px;
    float: left;
}

.btnLogin {
    margin-left: 0px;
    margin-right: 20px;
    background-color: #d90023;
}

.btnLogin:hover {
    background-color: #f92043;
    border-color: #f92043;
}

.btnLogin:active {
    background-color: #f92043 !important;
    border-color: #f92043 !important;
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0px #f92043 !important;
}

.btnLogin:focus {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
    box-shadow: 0 0 3px 3px #f92043;
}

.btnLogin:disabled {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
}


.btnLogout {
  margin-left: 20px !important;
}

.btnLogout:hover {
    background-color: #f92043;
    border-color: #f92043;
}

.btnLogout:active {
    background-color: #f92043 !important;
    border-color: #f92043 !important;
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0px #f92043 !important;
}

.btnLogout:focus {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
    box-shadow: 0 0 3px 3px #f92043;
}

.loginErrMsg {
    color: #d90023;
    font-size: 20px;
}

