.recordingFileAbsorbingColumn{
    width: 20%;
}

.recordingFile{
    width: 30%;
}

.recordingFileSize{
    width: 15%;
}

.recordingLastUpdated{
    width: 20%;
}

.recordingError{
    width: 15%;
}

.recordingInfoModal {
    width: 1000px;    /* Occupy the 90% of the screen width */
    max-width: 1250px;
}

.form-inline {
    margin: 0 auto;
}
