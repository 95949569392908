.CategoryAddLabel {
    color: #282c34;
}

.CategoryAddSubmitLabel {
    color: #282c34;
    margin-left: 15px;
}

.CategoryAddForm {
    width: 75%;
    margin: auto;
}

.CategoryAddControl {
    border: 1px solid #282c34;
}

.CategoryAddSubmit {
    margin-top:25px;
    text-align: left;
}
