.CategoryList {
  text-align: center;
}

.CategoryAddSpan {
    color: #f92043;
    margin-right:30px;
    font-size: 20px;
}

.btnCategory {
  margin-left: 0px;
}

.btnCategory:hover {
    background-color: #f92043;
    border-color: #f92043;
}

.btnCategory:active {
    background-color: #f92043 !important;
    border-color: #f92043 !important;
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0px #f92043 !important;
}

.btnCategory:focus {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
    box-shadow: 0 0 3px 3px #f92043;
}
