.ProcessAddLabel {
    color: #282c34;
}

.ProcessAddSubmitLabel {
    color: #282c34;
    margin-left: 15px;
}

.ProcessAddForm {
    width: 90%;
    margin: auto;
}

.ProcessAddControl {
    border: 1px solid #282c34;
}

.ProcessAddSubmit {
    margin-top:25px;
    text-align: left;
}
