.UserList {
  text-align: center;
}

.UserList-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .UserList-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.UserList-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.UserList-link {
  color: #61dafb;
}

.userAddErr {
    color: #f92043;
    margin-right:30px;
    font-size: 20px;
}

.userAbsorbingColumn{
    width: 40%;
}

.btnUser {
  margin-left: 0px;
}

.btnUser:hover {
    background-color: #f92043;
    border-color: #f92043;
}

.btnUser:active {
    background-color: #f92043 !important;
    border-color: #f92043 !important;
    border: 1px solid transparent !important;
    box-shadow: 0 0 0 0px #f92043 !important;
}

.btnUser:focus {
    background-color: #f92043;
    border-color: #f92043;
    border: 1px solid transparent;
    box-shadow: 0 0 3px 3px #f92043;
}

.paginationUsers {
    margin: auto;
    display: inline-block;
}
