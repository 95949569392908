.profileEditLabel {
    color: #282c34;
}

.profileEditSubmitLabel {
    color: #282c34;
    margin-left: 15px;
}

.profileEditForm {
    width: 50%;
    margin: auto;
}

.profileEditControl {
    border: 1px solid #282c34;
}

.profileEditSubmit {
    margin-top:25px;
    text-align: left;
}

.frmReset {

}

.btnResetApplication {
    color: #FFFFFF;
}
