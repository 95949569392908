.UserAddLabel {
    color: #282c34;
}

.UserAddSubmitLabel {
    color: #282c34;
    margin-left: 15px;
}

.UserAddForm {
    width: 90%;
    margin: auto;
}

.UserAddControl {
    border: 1px solid #282c34;
}

.UserAddSubmit {
    margin-top:25px;
    text-align: left;
}
