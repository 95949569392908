.Setup {
  text-align: center;
}

.setupLabel {
    float: left;
    padding-top: 10px;
    text-align: left;
}

.setupLabelErr {
    float: left;
    padding-top: 10px;
    text-align: left;
    margin: 18px 50px 0;
    font-size: 20px;
}

.divSetup {
    color: white;
}

.divSetupErr {
    color: red;
    white-space: pre-wrap;
}

.divSubmit {
    padding-top: 20px;
    float: left;
}
