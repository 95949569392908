.WorkflowLabel {
    color: #282c34;
}

.WorkflowSubmitLabel {
    color: #282c34;
    margin-left: 15px;
}

.WorkflowForm {
    width: 90%;
    margin: auto;
}

.WorkflowControl {
    border: 1px solid #282c34;
}

.WorkflowSubmit {
    margin-top:25px;
    text-align: left;
}
