.WorkflowAddLabel {
    color: #282c34;
}

.WorkflowAddSubmitLabel {
    color: #282c34;
    margin-left: 15px;
}

.WorkflowAddForm {
    width: 90%;
    margin: auto;
}

.WorkflowAddControl {
    border: 1px solid #282c34;
}

.WorkflowAddSubmit {
    margin-top:25px;
    text-align: left;
}
