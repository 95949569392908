.HistoryList {
  text-align: center;
}

.jumboHeader {
    background-color: #FFFFFF;
}

.blankBody {
    border: 0px;
}

.blankCell {
    border: 0px !important;
}

.historyTable {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
}

.historyTable td {
    border: 1px solid #ccc;
}

.absorbingColumn {
    width: 50%;
}

.contentTd {
    width: 1px;
    white-space: nowrap;
}

.paginationEvents {
    margin: auto;
    display: inline-block;
}

.page-item.active .page-link {
    background-color: #d1001f;
    border-color: #d1001f;
}

.page-link {
    color: #d1001f;
}

.page-link:hover {
    color: #660012;
}

.infoToolTip {
  min-width: 300px;
  max-width: 75%;
  max-height: 75%;
}

.inlineInfo {
    width: 200px;
}
