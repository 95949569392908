.CloudList {
  text-align: center;
}

.CloudList-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .CloudList-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.CloudList-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.CloudList-link {
  color: #61dafb;
}
.card-title {
    color: #282c34;
    width: 55%;
    text-align: right;
    margin-right: 75px;
}

.jumboHeader {
    background-color: #FFFFFF;
}

.cloudList {
    color: #282c34 !important;
    vertical-align: middle !important;
}

.cloudListTable {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
}

.absorbingColumn {
    width: 50%;
}

.paginationClouds {
    margin: auto;
    display: inline-block;
}

.downloadDropdown {
    background-color: #1e7e34;
}

.downloadLink {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: normal;
    background-color: #1e7e34;
}

.downloadLink:hover {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    background-color: #50A974;
}

.downloadLink:active {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: normal;
    background-color: #1e7e34;
}
