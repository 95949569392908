.mapLabel {
    color: #282c34;
}

.potree_container {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 1;
}

.potree_container_hidden {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: -1;
}

.potree_render_area {
    /* background-image: url('resources/images/background.jpg'); */
}

.potree_toolbar {
    position: absolute;
    z-index: 10000;
    left: 0px;
    top: 0px;
    background: #282c34;
    color: white;
    padding: 0.3em 0.8em;
    font-family: "system-ui";
    border-radius: 0em 0em 0.3em 0.3em;
    display: flex;
}

.MediaBar {
    position: absolute;
    z-index: 10000;
    left: 0px;
    bottom: 350px;
    width: 100%;
}

.MediaBarHidden {
    position: absolute;
    z-index: 10000;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.MediaBarDisabled {
    position: absolute;
    display: none;
}

.video_bar {
    position: absolute;
    z-index: 10000;
    left: 0px;
    bottom: 0px;
    height: 350px;
    width: 100%;
    background: #282c34;
    color: white;
    padding: 0em 0em;
    border-radius: 0em 0em 0em 0em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video_bar_hidden {
    position: absolute;
    display: none;
}

.video-react {
    padding-top: 0 !important;
    height: 100% !important;
    width: 25% !important;
}

.video1Enabled {
    display: block;
}

.video1Disabled {
    display: none;
}

.video2Enabled {
    display: block;
}

.video2Disabled {
    display: none;
}

.video3Enabled {
    display: block;
}

.video3Disabled {
    display: none;
}

.video4Enabled {
    display: block;
}

.video4Disabled {
    display: none;
}
