.TopBar {
  text-align: center;
  background-color: #282c34;
}

.navbar {
    background-color: #282c34 !important;
}

.navbar-brand {
    color: white !important;
}

.nav-link {
    color: white !important;
}

.mapLinkOn {
    color: #FFFFFF !important;
}

.mapLinkOff {
    color: #666666 !important;
}
