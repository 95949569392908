.mediaBar {
  width: 100%;
  height: 28px;
  background: #282c34;
  cursor: pointer;
  display:flex;
}

.bar {
  width: 0;
  height: 28px;
  transition: width 0.3s;
  background: #497684;
  position: absolute;
}

.hider {
  position: absolute;
}

.mediaInfo {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
